import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLogin from "../screens/auth/AdminLogin";
import Categories from "../screens/dashboard/Categories";
import CreateCategory from "../screens/dashboard/CreateCategory";
import CreatCategoryTeacher from "../screens/dashboard/CreatCategoryTeacher.js";
import Products from "../screens/dashboard/Products";
import UpdateCategory from "../screens/dashboard/UpdateCategory";
import UpdateCategoryTeacher from "../screens/dashboard/UpdateCategoryTeacher.js";
import CreateProduct from "../screens/dashboard/CreateProduct";
import CreateProductTeacher from "../screens/dashboard/CreateProductTeacher.js";
import Private from "./Private.js";
import Public from "./Public";
import EditProduct from "../screens/dashboard/EditProduct";
import EditProductTeacher from "../screens/dashboard/EditProductTeacher.js";
import Home from "../screens/home/Home";
import Login from "../screens/home/auth/Login";
import Register from "../screens/home/auth/Register";
import Dashboard from "../screens/users/Dashboard";
import UserRoute from "./UserRoute";
import UserAuthRoute from "./UserAuthRoute";
// import CatProducts from "../screens/home/CatProducts";
// import Product from "../screens/home/Product";
// import SearchProducts from "../screens/home/SearchProducts";
// import Cart from "../screens/home/Cart";
import Orders from "../screens/dashboard/Orders";
import OrderDetails from "../screens/dashboard/OrderDetails";
import UserOrders from "../screens/users/UserOrders";
import UserOrderDetails from "../screens/users/UserOrderDetails";
import InstructorLogin from "../screens/auth/InstructorLogin.js";
import InstructorRegister from "../screens/auth/InstructorRegister.js";
import PublicTeacher from "./PublicTeacher.js";
import TeacherPanel from "../screens/dashboard/TeacherPanel.js";
import PrivateTeacher from "./PrivateTeacher.js";
import CategoriesTeacher from "../screens/dashboard/CategoriesTeacher.js";
import CreateVideoTeacher from "../screens/dashboard/CreateVideoTeacher.js";
import CourseVideosTeacher from "../screens/dashboard/CourseVideosTeacher.js";
import HomeComon from "../components/CommonCompo/HomeComon.js";
import Sup from "../components/CommonCompo/Sup.js";
import FirstBac from "../components/CommonCompo/FirstBac.js";
import SecoundBac from "../components/CommonCompo/SecoundBac.js";
import CommonCore from "../components/CommonCompo/CommonCore.js";
import RegistrationInfo from "../components/CommonCompo/RegistrationInfo.js";
import Videoa from "../components/CommonCompo/Video/Videoa.js";
import CourcesMain from "../components/CommonCompo/CourcesMain.js";
import CourseMainSingle from "../components/CommonCompo/CourseMainSingle.js";
import AdminRegister from "../screens/auth/AdminRegister.js";
import TeacherStudent from "../screens/dashboard/TeacherStudent.js"
import StudentPaidCourses from '../screens/users/StudentPaidCourses.js'
import AdminTeacherGet from "../screens/dashboard/AdminTeacherGet.js";
import AdminTeacherUpdate from "../screens/dashboard/AdminTeacherUpdate.js";
import AdminSearchStudent from "../screens/dashboard/AdminSearchStudent.js";
import AdminSearchTeacher from "../screens/dashboard/AdminSearchTeacher.js";
import AdminSearchCourse from "../screens/dashboard/AdminSearchCourse.js";
import AdminCoursesGet from "../screens/dashboard/AdminCoursesGet.js";
import AdminUpdateCourse from "../screens/dashboard/AdminUpdateCourse.js";
import AdminCrtStudepakg from "../screens/dashboard/AdminCrtStudepakg.js";
import AdminGetStudentPackage from "../screens/dashboard/AdminGetStudentPackage.js";
import AdminUpdateStuPackag from "../screens/dashboard/AdminUpdateStuPackag.js";
import PackagemainCourse from "../components/CommonCompo/PackagemainCourse.js";
import AdminPaymentShow from "../screens/dashboard/AdminPaymentShow.js";
import FooterLms from "../components/CommonCompo/FooterLms.js";


const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<HomeComon />} />
        <Route path="/sup" element={<Sup />} />
        <Route path="/firstbac" element={<FirstBac />} />
        <Route path="/secoundbac" element={<SecoundBac />} />
        <Route path="/commoncore" element={<CommonCore />} />
        <Route path="/courses" element={<RegistrationInfo />} />
        <Route path="/coursesmain" element={<CourcesMain />} />
        <Route path="/coursesmainsingle" element={<CourseMainSingle />} />
        <Route path="/coursesmainsingle/:id" element={<CourseMainSingle />} />
        <Route path="/video" element={<Videoa />} />
        <Route path="/video/:id" element={<Videoa />} />
        <Route path="/video/:courseid/:videoid" element={<Videoa />} />

        {/* <Route path="/dashboard/instructors" element={<AdminTeacherGet />} />
        <Route path="/dashboard/instructors/:page" element={<AdminTeacherGet />} /> */}

        {/* <Route path="/dashboard/instructor/update/:id" element={<AdminTeacherUpdate />} />
        <Route path="/dashboard/instructor/update" element={<AdminTeacherUpdate />} /> */}
        {/* <Route path="/dashboard/instructors/:page" element={<AdminTeacherGet />} /> */}

        {/* <Route path="/dashboard/student/search" element={<AdminSearchStudent />} />


        <Route path="/dashboard/teacher/search" element={<AdminSearchTeacher />} />

        <Route path="/dashboard/course/search" element={<AdminSearchCourse />} /> */}


        {/* <Route path="/dashboard/get/admin/course" element={<AdminCoursesGet />} /> */}




        {/* <Route path="/dashboard/create/admin/package" element={<AdminCrtStudepakg />} /> */}

        {/* <Route path="/dashboard/get/admin/package" element={<AdminGetStudentPackage />} /> */}
        {/* <Route path="/dashboard/update/admin/course" element={<AdminUpdateCourse />} />
        <Route path="/dashboard/update/admin/course/:id" element={<AdminUpdateCourse />} />

        <Route path="/dashboard/update/admin/package" element={<AdminUpdateStuPackag />} />
        <Route path="/dashboard/update/admin/package/:id" element={<AdminUpdateStuPackag />} /> */}

        <Route path="/home/package" element={<PackagemainCourse />} />
        <Route path="/home/package/:id" element={<PackagemainCourse />} />
{/* 
        <Route path="/dashboard/admin/payment" element={<AdminPaymentShow />} /> */}



        {/* <Route path="/dashboard/instructor/update" element={<AdminSearchStudent />} /> */}


        <Route element={<UserAuthRoute />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
        </Route>
        <Route element={<UserRoute />}>
          <Route path="user" element={<Dashboard />} />
          <Route path="orders" element={<UserOrders />} />
          {/* <Route path="orders" element={<StudentPaidCourses />} /> */}

          {/* <Route path="orders/:page" element={<UserOrders />} /> */}
          <Route path="user-order-details" element={<UserOrderDetails />} />
          {/* <Route path="user-order-details/:id" element={<UserOrderDetails />} /> */}
        </Route>


        <Route path="auth">
          <Route
            path="admin-login"
            element={
              <Public>
                <AdminLogin />
              </Public>
            }
          />

          <Route
            path="admin-register"
            element={
              <Public>
                <AdminRegister />
              </Public>
            }
          />
        </Route>

        {/* teacher login */}

        <Route path="auth">
          <Route
            path="instructor-login"
            element={
              <PublicTeacher>
                <InstructorLogin />
              </PublicTeacher>
            }
          />
        </Route>

        <Route path="instructorregister" element={<PublicTeacher> <InstructorRegister /> </PublicTeacher>} />


        {/* teacher login end */}


        {/* teacher start */}

        <Route path="dashboard">

          <Route path="teacher" element={<PrivateTeacher><TeacherPanel /></PrivateTeacher>} />

          <Route
            path="teacher/:page"
            element={
              <PrivateTeacher>
                <TeacherPanel />
              </PrivateTeacher>
            }
          />

          <Route path="teacher/student" element={<PrivateTeacher><TeacherStudent /></PrivateTeacher>} />
          <Route
            path="teacher/student/:page"
            element={
              <PrivateTeacher>
                <TeacherStudent />
              </PrivateTeacher>
            }
          />
          <Route
            path="tvideo"
            element={
              <PrivateTeacher>
                <CreateVideoTeacher />
              </PrivateTeacher>
            }
          />
          <Route
            path="edit-product/:id"
            element={
              <PrivateTeacher>
                <EditProductTeacher />
              </PrivateTeacher>
            }
          />
          <Route
            path="edit-video/:id"
            element={
              <PrivateTeacher>
                <CourseVideosTeacher />
              </PrivateTeacher>
            }
          />
          <Route
            path="teacher/tcategories"
            element={
              <PrivateTeacher>
                <CategoriesTeacher />
              </PrivateTeacher>
            }
          />
          <Route
            path="teacher/tcategories/:page"
            element={
              <PrivateTeacher>
                <CategoriesTeacher />
              </PrivateTeacher>
            }
          />
          <Route
            path="teacher/create-category"
            element={
              <PrivateTeacher>
                <CreatCategoryTeacher />
              </PrivateTeacher>
            }
          />
          <Route
            path="teacher/update-category/:id"
            element={
              <PrivateTeacher>
                <UpdateCategoryTeacher />
              </PrivateTeacher>
            }
          />
          <Route
            // path="create-product"
            path="teacher/create-course"
            element={
              <PrivateTeacher>
                <CreateProductTeacher />
              </PrivateTeacher>
            }
          />

        </Route>

        {/* teacher end */}

        <Route path="dashboard">
          <Route
            path="products"
            element={
              <Private>
                <Products />
              </Private>
            }
          />
          <Route
            path="products/:page"
            element={
              <Private>
                <Products />
              </Private>
            }
          />
          {/* <Route path="instructors" element={<AdminTeacherGet />} />
          <Route path="instructors/:page" element={<AdminTeacherGet />} />
          <Route path="get/admin/course" element={<AdminCoursesGet />} />
          <Route path="get/admin/course/:page" element={<AdminCoursesGet />} />

          <Route path="student/search" element={<AdminSearchStudent />} />


          <Route path="teacher/search" element={<AdminSearchTeacher />} />

          <Route path="course/search" element={<AdminSearchCourse />} />
          <Route path="get/admin/package" element={<AdminGetStudentPackage />} />
          <Route path="create/admin/package" element={<AdminCrtStudepakg />} />

          <Route path="instructor/update/:id" element={<AdminTeacherUpdate />} />
          <Route path="instructor/update" element={<AdminTeacherUpdate />} />

          <Route path="update/admin/course" element={<AdminUpdateCourse />} />
        <Route path="update/admin/course/:id" element={<AdminUpdateCourse />} />
        
        <Route path="update/admin/package" element={<AdminUpdateStuPackag />} />
        <Route path="update/admin/package/:id" element={<AdminUpdateStuPackag />}/>
          
        <Route path="admin/payment" element={<AdminPaymentShow />} /> */}

<Route 
  path="instructors" 
  element={
    <Private>
      <AdminTeacherGet />
    </Private>
  } 
/>
<Route 
  path="instructors/:page" 
  element={
    <Private>
      <AdminTeacherGet />
    </Private>
  } 
/>
<Route 
  path="get/admin/course" 
  element={
    <Private>
      <AdminCoursesGet />
    </Private>
  } 
/>
<Route 
  path="get/admin/course/:page" 
  element={
    <Private>
      <AdminCoursesGet />
    </Private>
  } 
/>
<Route 
  path="student/search" 
  element={
    <Private>
      <AdminSearchStudent />
    </Private>
  } 
/>
<Route 
  path="teacher/search" 
  element={
    <Private>
      <AdminSearchTeacher />
    </Private>
  } 
/>
<Route 
  path="course/search" 
  element={
    <Private>
      <AdminSearchCourse />
    </Private>
  } 
/>
<Route 
  path="get/admin/package" 
  element={
    <Private>
      <AdminGetStudentPackage />
    </Private>
  } 
/>
<Route 
  path="create/admin/package" 
  element={
    <Private>
      <AdminCrtStudepakg />
    </Private>
  } 
/>
<Route 
  path="instructor/update/:id" 
  element={
    <Private>
      <AdminTeacherUpdate />
    </Private>
  } 
/>
<Route 
  path="instructor/update" 
  element={
    <Private>
      <AdminTeacherUpdate />
    </Private>
  } 
/>
<Route 
  path="update/admin/course" 
  element={
    <Private>
      <AdminUpdateCourse />
    </Private>
  } 
/>
<Route 
  path="update/admin/course/:id" 
  element={
    <Private>
      <AdminUpdateCourse />
    </Private>
  } 
/>
<Route 
  path="update/admin/package" 
  element={
    <Private>
      <AdminUpdateStuPackag />
    </Private>
  } 
/>
<Route 
  path="update/admin/package/:id" 
  element={
    <Private>
      <AdminUpdateStuPackag />
    </Private>
  } 
/>
<Route 
  path="admin/payment" 
  element={
    <Private>
      <AdminPaymentShow />
    </Private>
  } 
/>




          <Route
            path="edit-student/:id"
            element={
              <Private>
                <EditProduct />
              </Private>
            }
          />
          <Route
            path="categories"
            element={
              <Private>
                <Categories />
              </Private>
            }
          />
          <Route
            path="categories/:page"
            element={
              <Private>
                <Categories />
              </Private>
            }
          />
          <Route
            path="create-category"
            element={
              <Private>
                <CreateCategory />
              </Private>
            }
          />
          <Route
            path="update-category/:id"
            element={
              <Private>
                <UpdateCategory />
              </Private>
            }
          />
          <Route
            path="create-product"
            element={
              <Private>
                <CreateProduct />
              </Private>
            }
          />
          <Route path="orders" element={<Orders />} />
          <Route path="orders/:page" element={<Orders />} />
          <Route path="order-details/:id" element={<OrderDetails />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default Routing;