import { useState } from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from '../assets/images/logoteacher.png'


const Sidebar = ({ side, closeSidebar }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);


  const handleLanguageChange = (lng) => {
    console.log("lng",lng)

    i18n.changeLanguage(lng);
    console.log("lng",lng)
    setIsLangDropdownOpen(false); // Close the dropdown after selecting a language
    // setIsOpen(!isOpen);

  };

  
  // Toggle language dropdown
  const handleLangDropdownToggle = () => {
    setIsLangDropdownOpen(!isLangDropdownOpen);
  };

  return (
    <div
      className={`fixed top-0 ${side} sm:left-0 w-64 h-screen bg-gray-800 z-10 transition-all`}
    >
      <i
        className="bi bi-x-lg absolute top-4 right-4 sm:hidden block cursor-pointer text-lg"
        onClick={closeSidebar}
      ></i>
      <div className="bg-white p-4  ">
        <img src={logo} alt="logo" className="h-[50px] "  />
      </div>
      <ul className="mt-4">
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-card-list mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/teacher" className="text-base capitalize">
            {/* Cources */}
            {t('sidebar-teacher-courses')}
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/tvideo" className="text-base capitalize">
            {/* Video */}
            {t('sidebar-teacher-video')}

          </Link>
        </li>

        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/tvideo" className="text-base capitalize">
            {/* orders */}
            {t('sidebar-teacher-orders')}
          </Link>
        </li>


        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-people-fill mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/teacher/student" className="text-base capitalize">
            {/* Students */}
            {t('sidebar-teacher-students')}
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-bar-chart mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/teacher/tcategories" className="text-base capitalize">
            {/* categories */}
            {t('sidebar-teacher-categories')}
          </Link>
        </li>


        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
  {/* Language Dropdown */}
  <div className="relative bg-red-500" >
              {/* <div className="relative" ref={langDropdownRef}> */}
                <button onClick={handleLangDropdownToggle} className="text-black hover:bg-white hover:text-black px-2 py-2 rounded-md text-sm font-medium">
                  Language
                </button>
                {isLangDropdownOpen && (
                  <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                    <button onClick={() => handleLanguageChange('en')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">English</button>
                    <button onClick={() => handleLanguageChange('ar')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">العربية</button>
                    <button onClick={() => handleLanguageChange('fr')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">française</button>
                  </div>
                )}
              </div>
          </li>








      </ul>
    </div>
  );
};
export default Sidebar;
