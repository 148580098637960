// HeroSection.js
import React from 'react';
import { useTranslation } from 'react-i18next';

// import { useGetMainCoursesQuery } from '../../store/services/CourseServiceMain';
import { useGetPackagesoneQuery } from '../../store/services/SupService'; // Adjust import path
import { Link } from 'react-router-dom';

const boxes = [
  {
    id: 1,
    title: 'Mathematics 2Bac Sm',
    coursesCount: 6,
    price: '98 د.م.',
    imageUrl: 'https://cdn.filestackcontent.com/elFL8LNT4SYTTmsLbgMM',
    link: '/',
  },
  {
    id: 2,
    title: 'Physics chemistry 2Bac Sm',
    coursesCount: 8,
    price: '98 د.م.',
    imageUrl: 'https://cdn.filestackcontent.com/6bSGZbicTqnYpgZiLqau',
    link: '/',
  },

];


const Headb = () => {
  const { t } = useTranslation();

  const { data: packages, error, isLoading } = useGetPackagesoneQuery();
  const serverBaseURL = 'https://api.wgktrade.com'; // Change this as needed

  console.log("packages", packages)
  if (isLoading) return <p>Loading...</p>;

  if (error) {
    return (
      <>
        {error.data?.msg || 'Error fetching packages'}
        <section className="  bg-gray-200 ">
          <div className="container mx-auto text-center">
            {/* <h2 className="text-3xl font-bold text-center mb-[20px]">2 Bac sm packs</h2> */}

            <div className="max-w-7xl mx-auto px-5 py-5 ">
              <h2 className="text-3xl font-bold text-center mb-[60px]">2 Bac sm packs</h2>
              <div className="flex flex-wrap justify-center gap-5">
                {boxes.map((box) => (
                  <div
                    key={box.id}
                    className=" bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300 border border-transparent hover:border-red-500 hover:-translate-y-1 transform transition-transform duration-300 w-full sm:w-[290px] flex flex-col"
                  >
                    <a href={box.link} className="block">
                      <div className="relative h-0 pb-[56.25%] overflow-hidden rounded-t-lg">
                        <img
                          src={box.imageUrl}
                          alt={box.title}
                          className="absolute top-0 left-0 w-full h-full object-cover"
                        />
                      </div>
                      <div className="p-4 flex flex-col justify-between items-center h-[225px]">
                        <h3 className="text-xl font-semibold mb-2 text-center">{box.title}</h3>
                        <div className="flex justify-between w-full">
                          <span className="text-left">{box.coursesCount && `Courses: ${box.coursesCount}`}</span>
                          <span className="text-right text-red-500">{t('price')}:{box.price}</span>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div></div></div></section>
      </>
    );
  }


  return (
    // <section className="bg-blue-500  py-20">
    <section className="  bg-gray-200 ">
      <div className="container mx-auto text-center">
        {/* <h2 className="text-3xl font-bold text-center mb-[20px]">2 Bac sm packs</h2> */}

        <div className="max-w-7xl mx-auto px-5 py-5 ">
          <h2 className="text-3xl font-bold text-center mb-[60px]">2 Bac sm packs</h2>
          <div className="flex flex-wrap justify-center gap-5">
            {packages && packages.length > 0 ? (

              <>
                {packages.map((box) => (
                  <div
                    key={box.id}
                    className=" bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300 border border-transparent hover:border-red-500 hover:-translate-y-1 transform transition-transform duration-300 w-full sm:w-[290px] flex flex-col"
                  >
                    <Link to={`/home/package/${box._id}`} className="block">

                      <div className="relative h-0 pb-[56.25%] overflow-hidden rounded-t-lg">
                        <img
                          src={`${serverBaseURL}${box.image_url}`}
                          alt={box.package_name}
                          className="absolute top-0 left-0 w-full h-full object-cover"
                        />
                      </div>
                      <div className="p-4 flex flex-col justify-between items-center h-[225px]">
                        <h3 className="text-xl font-semibold mb-2 text-center">{box.package_name}</h3>
                        <div className="flex justify-between w-full">
                          <span className="text-left">Courses: {box.courses.length}</span>
                          <span className="text-right text-red-500">{t('price')}:{box.total_fees}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </>
            ) : (
              <>
                {boxes.map((box) => (
                  <div
                    key={box.id}
                    className=" bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300 border border-transparent hover:border-red-500 hover:-translate-y-1 transform transition-transform duration-300 w-full sm:w-[290px] flex flex-col"
                  >
                    <a href={box.link} className="block">
                      <div className="relative h-0 pb-[56.25%] overflow-hidden rounded-t-lg">
                        <img
                          src={box.imageUrl}
                          alt={box.title}
                          className="absolute top-0 left-0 w-full h-full object-cover"
                        />
                      </div>
                      <div className="p-4 flex flex-col justify-between items-center h-[225px]">
                        <h3 className="text-xl font-semibold mb-2 text-center">{box.title}</h3>
                        <div className="flex justify-between w-full">
                          <span className="text-left">{box.coursesCount && `Courses: ${box.coursesCount}`}</span>
                          <span className="text-right text-red-500">{t('price')}:{box.price}</span>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </>
            )}

            {/* {boxes.map((box) => (
            <div
              key={box.id}
              className=" bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300 border border-transparent hover:border-red-500 hover:-translate-y-1 transform transition-transform duration-300 w-full sm:w-[290px] flex flex-col"
            >
              <a href={box.link} className="block">
                <div className="relative h-0 pb-[56.25%] overflow-hidden rounded-t-lg">
                  <img
                    src={box.imageUrl}
                    alt={box.title}
                    className="absolute top-0 left-0 w-full h-full object-cover"
                  />
                </div>
                <div className="p-4 flex flex-col justify-between items-center h-[225px]">
                  <h3 className="text-xl font-semibold mb-2 text-center">{box.title}</h3>
                  <div className="flex justify-between w-full">
                    <span className="text-left">{box.coursesCount && `Courses: ${box.coursesCount}`}</span>
                    <span className="text-right text-red-500">{box.price}</span>
                  </div>
                </div>
              </a>
            </div>
          ))} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Headb;

