
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import toast, { Toaster } from "react-hot-toast";
import h2p from "html2plaintext";
import "react-quill/dist/quill.snow.css";
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import WrapperTeacher from "./WrapperTeacher";
// import { useAllCategoriesQuery } from "../../store/services/categoryServiceTeacher";
import { useUpdateProductMutation, useGetProductQuery,useAllCategoriesQuery } from "../../store/services/productServiceTeacher";
import Spinner from "../../components/Spinner";
import { setSuccess } from "../../store/reducers/globalReducer";

const EditProduct = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { data: product, isFetching: fetching } = useGetProductQuery(id);
  const { data = [], isFetching: categoriesFetching } = useAllCategoriesQuery();
  const [value, setValue] = useState('');
  const [state, setState] = useState({
    title: "",
    price: 0,
    category: "",
    description: '',
    productId: id,
    image1: null
  });
  const [imagePreview, setImagePreview] = useState("");

  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setState({ ...state, image1: e.target.files[0] });
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const [updateProduct, response] = useUpdateProductMutation();

  const updateProductHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('data', JSON.stringify({
      title: state.title,
      price: state.price,
      category: state.category,
      description: value,
      productId: state.productId
    }));
    formData.append('description', state.description); // Text area content
    if (state.image1) {
      formData.append('image1', state.image1);
    }

    updateProduct(formData);
  };

  useEffect(() => {
    if (!response.isSuccess && response?.error?.data?.errors) {
      response.error.data.errors.forEach((err) => {
        toast.error(err.msg);
      });
    }
  }, [response?.error?.data?.errors]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (response?.isSuccess) {
      dispatch(setSuccess(response?.data?.msg));
      navigate("/dashboard/teacher");
    }
  }, [response?.isSuccess]);

  useEffect(() => {
    if (!fetching && product) {
      setState({
        title: product.title,
        price: product.price,
        category: product.category,
        description: h2p(product.description),
        productId: id
      });
      setValue(h2p(product.description));
      if (product.thumbnail_url) {
        setImagePreview(product.thumbnail_url);
      }
    }
  }, [product, fetching]);

  return (
    <WrapperTeacher>
      <ScreenHeaderTeacher>
        <Link to="/dashboard/teacher" className="btn-dark">
          <i className="bi bi-arrow-left-short"></i>
           {/* Products List */}

           {t("dashboard-editProduct-teacher-productList")}
        </Link>
      </ScreenHeaderTeacher>
      <Toaster position="top-right" reverseOrder={true} />
      {!fetching ? (
        <div className="flex flex-wrap -mx-3 bg-yellow-300 ">
          <form className="w-full xl:w-8/12 p-3" onSubmit={updateProductHandler}>
            <h3 className="pl-3 capitalize text-lg font-medium text-black">
              {/* Edit Course Details */}
              {t("dashboard-editProduct-teacher-editCourseDetails")}
            </h3>
            <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="title" className="label text-black">
                  {/* Title */}
                  {t("dashboard-editProduct-teacher-title")}
                </label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  id="title"
                  placeholder="Title..."
                  onChange={handleInput}
                  value={state.title}
                />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="price" className="label text-black">
                  {/* Price */}
                  {t("dashboard-editProduct-teacher-price")}

                </label>
                <input
                  type="number"
                  name="price"
                  className="form-control"
                  id="price"
                  placeholder="Price..."
                  onChange={handleInput}
                  value={state.price}
                />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="category" className="label text-black">
                  {/* Category */}
                  {t("dashboard-editProduct-teacher-category")}

                </label>
                {!categoriesFetching ? (
                  data?.categories?.length > 0 && (
                    <select
                      name="category"
                      id="category"
                      className="form-control"
                      onChange={handleInput}
                      value={state.category}
                    >
                      <option value="">
                        {/* Choose category */}
                        {t("dashboard-editProduct-teacher-chooseCategory")}
                        </option>
                      {data?.categories?.map((category) => (
                        <option value={category.name} key={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  )
                ) : (
                  <Spinner />
                )}
              </div>

              <div className="w-full p-3">
                <label htmlFor="description" className="label text-black">
                  {/* Description */}

                  {t("dashboard-editProduct-teacher-description")}

                </label>
                <ReactQuill
                  theme="snow"
                  id="description"
                  value={value}
                  onChange={setValue}
                  placeholder="Description..."
                />
              </div>

              <div className="w-full p-3">
                <label htmlFor="image1" className="label text-black">
                  {/* Product Image */}

                  {t("dashboard-editProduct-teacher-productImage")}

                </label>
                <input
                  type="file"
                  name="image1"
                  id="image1"
                  accept="image/jpeg, image/png"
                  onChange={handleFileChange}
                  className="form-control"
                />
                {imagePreview && (
                  <div className="mt-2">
                    <img
                      src={imagePreview}
                      alt={t("dashboard-editProduct-teacher-productPreview")}
                      className="w-full h-auto text-black"
                    />
                  </div>
                )}
              </div>

              <div className="w-full p-3">
                <input
                  type="submit"
                  // value={response.isLoading ? "Loading..." : "Save Product"}
                  value={response.isLoading ? t("dashboard-editProduct-teacher-loading") : t("dashboard-editProduct-teacher-saveProduct")}

                  disabled={response.isLoading}
                  className="btn btn-indigo"
                />
              </div>
            </div>
          </form>
        </div>
      ) : (
        <Spinner />
      )}
    </WrapperTeacher>
  );
};

export default EditProduct;
